import produce from 'immer'
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_NOT_FOUND,
  USER_LOGIN_SERVER_ERROR,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
} from '../actions/UserActions'

const initialState = {
  __persist: true,
  isLoading: false,
  authorized: false,
  type: '',
  id: '',
  attributes: {
    login: '',
    name: '',
    token: '',
  },
  error: {
    code: null,
    text: '',
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case USER_LOGIN_NOT_FOUND:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.error = {
          code: 404,
          text: 'Введен неверный логин или пароль',
        }
      })
    case USER_LOGIN_SERVER_ERROR:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = false
        draftState.error = {
          code: 500,
          text:
            'Возникла проблема со входом. Попробуйте еще раз и обратитесь в службу поддержки, если проблема повторится',
        }
      })
    case USER_LOGIN_SUCCESS:
      const { data } = action.payload
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.authorized = true
        draftState.error = {
          code: null,
          text: '',
        }
        draftState.type = data.type
        draftState.id = data.id
        draftState.attributes = data.attributes
      })
    case USER_LOGOUT:
      return initialState
    default:
      return state
  }
}
