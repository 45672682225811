import React, { PureComponent } from 'react'
import { Row, Col, Checkbox, Select, Button, message, Input } from 'antd'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import NoSleep from 'nosleep.js'

import {
  init1pParserForm,
  updateSettings1pParserForm,
  run1pParserRequest,
  run1pParserSuccess,
  finish1pParserOne,
} from '../../../../redux/actions/FormsActions'

import { writeConsole } from '../../../../redux/actions/FilesActions'

const { Option } = Select

const noSleep = new NoSleep()

const socket = io(`${process.env.REACT_APP_SOCKET_HOST}/`, {
  rejectUnauthorized: false,
})

class Form extends PureComponent {
  componentDidMount() {
    this.props.init1pParserForm()
    this.socketFunc()
  }

  socketFunc = () => {
    socket.connect()

    socket.on('connect_error', function(error) {
      console.log('connect_error', error)
    })

    socket.on('connect', function() {})

    // one finished
    socket.on('fileParsingDocxTo1pFinished', uid => {
      this.props.finish1pParserOne(uid)
    })

    // new log
    socket.on('fileParsingDocxTo1pLog', log => {
      this.props.writeConsole(log, 'info')
    })

    // new warning
    socket.on('fileParsingDocxTo1pWarning', log => {
      this.props.writeConsole(log, 'warning')
    })

    // processing finished
    socket.on('runParserDocxTo1pRes', uids => {
      this.props.run1pParserSuccess(uids)
      noSleep.disable()
    })

    // processing error
    socket.on('runParserDocxTo1pError', error => {
      message.error(error.message)
      noSleep.disable()
    })
  }

  onParse() {
    const files = this.props.files.files
      .filter(item => item.status === 'uploaded')
      .map(item => item.uid)
    const parserForm = this.props.forms.parser_1p

    if (files.length === 0) {
      message.error('Загрузите по крайней мере один файл для обработки')
    } else if (
      !parserForm.select_names &&
      !parserForm.select_dates_and_places
    ) {
      message.error('Выберите по крайней мере один параметр для выделения')
    } else if (files.length > 10) {
      message.error(
        'За один раз можно обрабатывать не более десяти файлов. Удалите лишние файлы и загрузите их перед следующей загрузкой'
      )
    } else {
      socket.emit('runParserDocxTo1p', {
        type: parserForm.type,
        select_names: parserForm.select_names,
        select_dates_and_places: parserForm.select_dates_and_places,
        simple_markup: parserForm.simple_markup,
        default_place: parserForm.default_place,
        uuids: files,
      })
      this.props.run1pParserRequest(files)
      noSleep.enable()
    }
  }

  render() {
    const parserForm = this.props.forms.parser_1p

    return (
      <Row gutter={16}>
        <Col span={22}>
          <Row className={'Form__container'}>
            <Col span={12}>
              <Checkbox
                checked={parserForm.select_names}
                onChange={e => {
                  this.props.updateSettings1pParserForm(
                    'select_names',
                    e.target.checked
                  )
                }}
              >
                выделить имена
              </Checkbox>
            </Col>
            <Col span={12}>
              <Checkbox
                checked={parserForm.select_dates_and_places}
                onChange={e => {
                  this.props.updateSettings1pParserForm(
                    'select_dates_and_places',
                    e.target.checked
                  )
                }}
              >
                выделить даты + места
              </Checkbox>
            </Col>
          </Row>

          <Row className={'Form__container'}>
            <Col span={12}>
              <Checkbox
                checked={parserForm.simple_markup}
                onChange={e => {
                  this.props.updateSettings1pParserForm(
                    'simple_markup',
                    e.target.checked
                  )
                }}
              >
                упрощенная разметка
              </Checkbox>
            </Col>
          </Row>

          <Select
            className={'Form__container'}
            defaultValue={parserForm.type}
            style={{ width: '100%' }}
            onChange={value =>
              this.props.updateSettings1pParserForm('type', value)
            }
          >
            <Option value="авт">Дневник</Option>
            <Option value="ред">Мемуар</Option>
          </Select>

          <Input
            placeholder="Автоместо"
            defaultValue={parserForm.default_place}
            onChange={e =>
              this.props.updateSettings1pParserForm(
                'default_place',
                e.target.value
              )
            }
          />

          <Button
            block
            type="primary"
            className={'Form__container'}
            style={{ marginBottom: '3rem' }}
            loading={parserForm.isLoading}
            onClick={e => this.onParse()}
          >
            Обработать
          </Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.files,
    forms: store.forms,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init1pParserForm: () => dispatch(init1pParserForm()),
    updateSettings1pParserForm: (param, value) =>
      dispatch(updateSettings1pParserForm(param, value)),
    run1pParserRequest: uids => dispatch(run1pParserRequest(uids)),
    run1pParserSuccess: uids => dispatch(run1pParserSuccess(uids)),
    writeConsole: (value, type = 'info') => dispatch(writeConsole(value, type)),
    finish1pParserOne: uid => dispatch(finish1pParserOne(uid)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
