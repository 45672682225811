export const PEOPLE_LOAD_REQUEST = 'MENTIONED_PEOPLE/PEOPLE_LOAD_REQUEST'
export const PEOPLE_LOAD_SUCCESS = 'MENTIONED_PEOPLE/PEOPLE_LOAD_SUCCESS'

export function getPeopleList(
  pageNumber = 1,
  search = '',
  sort = null,
  filterForm = null
) {
  let additionalParams = {}

  if (sort) {
    additionalParams.sort = sort.field
    additionalParams.sort_type = sort.type
  }

  if (filterForm) {
    additionalParams.user_created_id = filterForm.user_created_id.map(item =>
      parseInt(item)
    )
    additionalParams.status = filterForm.status.map(item => parseInt(item))
    additionalParams.search_fields = filterForm.search_fields
    additionalParams.is_hide_double = filterForm.is_hide_double
    additionalParams.is_exact_search = filterForm.is_exact_search

    additionalParams.lastName = filterForm.lastName
    additionalParams.firstName = filterForm.firstName
    additionalParams.thirdName = filterForm.thirdName
    additionalParams.nickname = filterForm.nickname
    additionalParams.comment = filterForm.comment
  }

  return dispatch => {
    dispatch({
      types: [PEOPLE_LOAD_REQUEST, PEOPLE_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/mentioned-people/get-all',
          params: {
            page: pageNumber,
            search,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const DELETE_PERSON_REQUEST = 'MENTIONED_PEOPLE/DELETE_PERSON_REQUEST'
export const DELETE_PERSON_SUCCESS = 'MENTIONED_PEOPLE/DELETE_PERSON_SUCCESS'

export function deletePerson(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_PERSON_REQUEST, DELETE_PERSON_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/mentioned-people/delete/${id}`,
        },
        id,
      },
    })
  }
}

export const SET_SEARCH_FIELDS = 'MENTIONED_PEOPLE/SET_SEARCH_FIELDS'

export function setSearchFields(fields) {
  return dispatch => {
    dispatch({
      type: SET_SEARCH_FIELDS,
      payload: {
        fields,
      },
    })
  }
}

export const SET_DOUBLE_SEARCH = 'MENTIONED_PEOPLE/SET_DOUBLE_SEARCH'

export function setDoubleSearch(value) {
  return dispatch => {
    dispatch({
      type: SET_DOUBLE_SEARCH,
      payload: {
        value,
      },
    })
  }
}

export const SET_EXACT_SEARCH = 'MENTIONED_PEOPLE/SET_EXACT_SEARCH'

export function setExactSearch(value) {
  return dispatch => {
    dispatch({
      type: SET_EXACT_SEARCH,
      payload: {
        value,
      },
    })
  }
}

// PERSON FROM START
export const OPEN_PERSON_FORM = 'MENTIONED_PEOPLE/OPEN_PERSON_FORM'

export function openPersonForm(is_approved = false) {
  return dispatch => {
    dispatch({
      type: OPEN_PERSON_FORM,
      payload: {
        is_approved,
      },
    })
  }
}

export const OPEN_EDIT_PERSON_FORM = 'MENTIONED_PEOPLE/OPEN_EDIT_PERSON_FORM'

export function openEditPersonForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_PERSON_FORM,
      payload: {
        id,
      },
    })
  }
}

export const CLOSE_PERSON_FORM = 'MENTIONED_PEOPLE/CLOSE_PERSON_FORM'

export function closePersonForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_PERSON_FORM,
    })
  }
}

export const CREATE_PERSON_REQUEST = 'MENTIONED_PEOPLE/CREATE_PERSON_REQUEST'
export const CREATE_PERSON_SUCCESS = 'MENTIONED_PEOPLE/CREATE_PERSON_SUCCESS'

export function createPerson(
  firstName,
  lastName,
  thirdName,
  nickname,
  sex,
  birthDay,
  deathDay,
  comment,
  is_approved
) {
  return dispatch => {
    dispatch({
      types: [CREATE_PERSON_REQUEST, CREATE_PERSON_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: '/mentioned-people/create',
          data: {
            firstName,
            lastName,
            thirdName,
            nickname,
            sex,
            birthDay,
            deathDay,
            comment,
            is_approved,
          },
        },
      },
    })
  }
}

export const UPDATE_PERSON_REQUEST = 'MENTIONED_PEOPLE/UPDATE_PERSON_REQUEST'
export const UPDATE_PERSON_SUCCESS = 'MENTIONED_PEOPLE/UPDATE_PERSON_SUCCESS'

export function updatePerson(
  id,
  firstName,
  lastName,
  thirdName,
  nickname,
  sex,
  birthDay,
  deathDay,
  comment,
  is_approved,
  parent_id
) {
  return dispatch => {
    dispatch({
      types: [UPDATE_PERSON_REQUEST, UPDATE_PERSON_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: '/mentioned-people/update/' + id,
          data: {
            firstName,
            lastName,
            thirdName,
            nickname,
            sex,
            birthDay,
            deathDay,
            comment,
            is_approved,
            parent_id,
          },
        },
      },
    })
  }
}

export const SET_FIELD_FOR_PERSON = 'MENTIONED_PEOPLE/SET_FIELD_FOR_PERSON'

export function setFieldForPerson(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_PERSON,
      payload: {
        field,
        value,
      },
    })
  }
}

export const PEOPLE_SEARCH_REQUEST = 'MENTIONED_PEOPLE/PEOPLE_SEARCH_REQUEST'
export const PEOPLE_SEARCH_SUCCESS = 'MENTIONED_PEOPLE/PEOPLE_SEARCH_SUCCESS'

export function searchPeople(search = '') {
  return dispatch => {
    dispatch({
      types: [PEOPLE_SEARCH_REQUEST, PEOPLE_SEARCH_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/mentioned-people/get-all',
          params: {
            page: 1,
            search,
          },
        },
      },
    })
  }
}
// PERSON FROM END
