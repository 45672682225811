import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import * as History from 'history'
import rootReducer from './reducers'
import persistState from 'redux-localstorage'
import persistSlicer from 'redux-localstorage-slicer'
import axiosMiddleware from 'redux-axios-middleware'

import { client, middlewareConfig } from './middleware/api'

export const history = History.createBrowserHistory()

const initialState = {}
const enhancers = []
const middleware = [
  thunk,
  routerMiddleware(history),
  axiosMiddleware(client, middlewareConfig),
]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const REDUX_LOCALSTORAGE_VERSION = 8

const composedEnhancers = compose(
  persistState(null, {
    slicer: persistSlicer(REDUX_LOCALSTORAGE_VERSION),
  }),
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  connectRouter(history)(rootReducer),
  initialState,
  composedEnhancers
)
