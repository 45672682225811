import React, { PureComponent } from 'react'
import { Form, Select, Input, Button, Spin, Icon, Switch } from 'antd'
import { connect } from 'react-redux'

import {
  createUser,
  updateUser,
  setFieldForUser,
} from '../../../../redux/actions/UsersActions'

const { Option } = Select

class UserForm extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values)
        const { userForm } = this.props.users
        if (userForm.id) {
          this.props.updateUser(
            userForm.id,
            userForm.firstname,
            userForm.lastname,
            userForm.login,
            userForm.password,
            userForm.role,
            userForm.email,
            userForm.is_send_notifications
          )
        } else {
          this.props.createUser(
            userForm.firstname,
            userForm.lastname,
            userForm.login,
            userForm.password,
            userForm.role,
            userForm.email,
            userForm.is_send_notifications
          )
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const userForm = this.props.users.userForm

    return userForm.isOpen ? (
      <Spin spinning={userForm.isSending} indicator={antIcon}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="Имя">
            {getFieldDecorator('firstname', {
              rules: [{ required: true, message: 'Введите имя пользователя' }],
              initialValue: userForm.firstname,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForUser('firstname', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Фамилия">
            {getFieldDecorator('lastname', {
              rules: [
                { required: true, message: 'Введите фамилию пользователя' },
              ],
              initialValue: userForm.lastname,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForUser('lastname', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Логин">
            {getFieldDecorator('login', {
              rules: [
                { required: true, message: 'Введите логин пользователя' },
              ],
              initialValue: userForm.login,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForUser('login', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Пароль">
            {getFieldDecorator('password', {
              rules: [
                {
                  required: !userForm.id ? true : false,
                  message: 'Введите пароль пользователя',
                },
              ],
              initialValue: '',
            })(
              <Input.Password
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForUser('password', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Роль">
            {getFieldDecorator('role', {
              rules: [{ required: true, message: 'Выберите роль' }],
              initialValue: userForm.role,
            })(
              <Select
                placeholder="Выберите"
                onChange={value => this.props.setFieldForUser('role', value)}
              >
                <Option value="volunteer">Волонтер</Option>
                <Option value="admin">Админ</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="E-mail">
            {getFieldDecorator('email', {
              rules: [
                { required: false, message: 'Введите e-mail пользователя' },
              ],
              initialValue: userForm.email,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForUser('email', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Оповещения">
            <Switch
              checked={userForm.is_send_notifications}
              onChange={value =>
                this.props.setFieldForUser('is_send_notifications', value)
              }
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    users: store.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createUser: (
      firstname,
      lastname,
      login,
      password,
      role,
      email,
      is_send_notifications
    ) =>
      dispatch(
        createUser(
          firstname,
          lastname,
          login,
          password,
          role,
          email,
          is_send_notifications
        )
      ),
    updateUser: (
      id,
      firstname,
      lastname,
      login,
      password,
      role,
      email,
      is_send_notifications
    ) =>
      dispatch(
        updateUser(
          id,
          firstname,
          lastname,
          login,
          password,
          role,
          email,
          is_send_notifications
        )
      ),
    setFieldForUser: (field, value) => dispatch(setFieldForUser(field, value)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'UserForm' })(UserForm))
