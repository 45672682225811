import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Button, Upload, message, Tooltip } from 'antd'
import { UploadOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

import {
  addFile,
  removeFile,
  uploadFile,
} from '../../../redux/actions/FilesActions'

class DownloadForm extends PureComponent {
  render() {
    const url = process.env.REACT_APP_API_HOST
    const self = this
    const files = this.props.files.files.filter(
      item => item.status === 'uploaded'
    )
    const allowTypes = this.props.allowTypes

    const props = {
      name: 'filedata',
      action: `${url}/file/upload`,
      headers: {
        authorization: 'Bearer ' + this.props.user.attributes.token,
      },
      data(file) {
        return {
          uuid: file.uid,
          allowTypes,
        }
      },
      showUploadList: false,
      onChange(info) {
        if (info.file.status === 'uploading') {
          self.props.addFile(info.file.uid, info.file.name)
        }
        if (info.file.status === 'done') {
          self.props.uploadFile(info.file.uid)
        } else if (info.file.status === 'error') {
          self.props.removeFile(info.file.uid)
          if (info.file.error.status === 406) {
            message.error(`${info.file.name} неверный формат файла.`)
          } else {
            message.error(
              `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
            )
          }
        }
      },
    }
    return (
      <div className={'Form__container'}>
        <Upload {...props}>
          <Button disabled={files.length === 10}>
            <UploadOutlined /> Загрузить {this.props.allowTypes.join(', ')}
          </Button>
        </Upload>
        {this.props.hint && (
          <Tooltip placement="top" title={this.props.hint}>
            <QuestionCircleOutlined className={'DownloadForm__hint'} />
          </Tooltip>
        )}
        {files.length === 10 && (
          <span style={{ marginLeft: '10px' }}>
            за один раз можно обработать не более десяти файлов
          </span>
        )}
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    files: store.files,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addFile: (uid, name) => dispatch(addFile(uid, name)),
    removeFile: uid => dispatch(removeFile(uid)),
    uploadFile: uid => dispatch(uploadFile(uid)),
  }
}

DownloadForm.propTypes = {
  allowTypes: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadForm)
