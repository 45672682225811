import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import MainLayout from '../MainLayout/MainLayoutComponent'
import { history } from '../../redux/configureStore'

import Login from '../Login/LoginComponent'

class App extends PureComponent {
  render() {
    if (!this.props.user.authorized && history.location.pathname === '/login') {
      return <Login />
    }

    // If auth was success show one of application page
    return <MainLayout />
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
  }
}

export default connect(mapStateToProps)(App)
