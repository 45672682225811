import React, { PureComponent } from 'react'
import { Form, Select, Input, Button, Spin, Icon, Switch } from 'antd'
import { connect } from 'react-redux'

import {
  createPerson,
  updatePerson,
  setFieldForPerson,
  searchPeople,
} from '../../../../redux/actions/MentionedPeopleActions'

const { Option } = Select
const { TextArea } = Input

class PersonForm extends PureComponent {
  componentDidMount() {
    const personForm = this.props.mentioned_people.personForm
    this.props.searchPeople(personForm.parent_id ? personForm.parent_id : '')
  }

  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values)
        const { personForm } = this.props.mentioned_people
        if (personForm.id) {
          this.props.updatePerson(
            personForm.id,
            personForm.firstName,
            personForm.lastName,
            personForm.thirdName,
            personForm.nickname,
            personForm.sex,
            personForm.birthDay,
            personForm.deathDay,
            personForm.comment,
            personForm.is_approved,
            personForm.parent_id
          )
        } else {
          this.props.createPerson(
            personForm.firstName,
            personForm.lastName,
            personForm.thirdName,
            personForm.nickname,
            personForm.sex,
            personForm.birthDay,
            personForm.deathDay,
            personForm.comment,
            personForm.is_approved
          )
        }
      }
    })
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />
    const { getFieldDecorator } = this.props.form

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    }

    const personForm = this.props.mentioned_people.personForm
    const user = this.props.user

    return personForm.isOpen ? (
      <Spin spinning={personForm.isSending} indicator={antIcon}>
        <Form {...formItemLayout} onSubmit={this.handleSubmit}>
          <Form.Item label="Фамилия">
            {getFieldDecorator('lastName', {
              rules: [{ required: false }],
              initialValue: personForm.lastName,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForPerson('lastName', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Имя">
            {getFieldDecorator('firstName', {
              rules: [{ required: false }],
              initialValue: personForm.firstName,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForPerson('firstName', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Отчество">
            {getFieldDecorator('thirdName', {
              rules: [{ required: false }],
              initialValue: personForm.thirdName,
            })(
              <Input
                placeholder="Введите"
                onChange={e =>
                  this.props.setFieldForPerson('thirdName', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Другие имена">
            {getFieldDecorator('nickname', {
              rules: [{ required: false }],
              initialValue: personForm.nickname,
            })(
              <TextArea
                rows={2}
                onChange={e =>
                  this.props.setFieldForPerson('nickname', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Пол">
            {getFieldDecorator('sex', {
              rules: [{ required: false }],
              initialValue: personForm.sex,
            })(
              <Select
                placeholder="Выберите"
                onChange={value => this.props.setFieldForPerson('sex', value)}
              >
                <Option value={0}>Не указано</Option>
                <Option value={2}>Мужской</Option>
                <Option value={1}>Женский</Option>
              </Select>
            )}
          </Form.Item>

          <Form.Item label="Дата рождения*">
            {getFieldDecorator('birthDay', {
              rules: [{ required: false }],
              initialValue: personForm.birthDay,
            })(
              <Input
                placeholder="ГГГГ-ММ-ДД"
                onChange={e =>
                  this.props.setFieldForPerson('birthDay', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Дата смерти*">
            {getFieldDecorator('deathDay', {
              rules: [{ required: false }],
              initialValue: personForm.deathDay,
            })(
              <Input
                placeholder="ГГГГ-ММ-ДД"
                onChange={e =>
                  this.props.setFieldForPerson('deathDay', e.target.value)
                }
              />
            )}
          </Form.Item>

          <Form.Item label="Комментарий">
            {getFieldDecorator('comment', {
              rules: [{ required: false }],
              initialValue: personForm.comment,
            })(
              <TextArea
                rows={6}
                onChange={e =>
                  this.props.setFieldForPerson('comment', e.target.value)
                }
              />
            )}
          </Form.Item>

          {user.attributes.role !== 'volunteer' && (
            <Form.Item label="Подтверждено">
              <Switch
                checked={personForm.is_approved}
                onChange={value =>
                  this.props.setFieldForPerson('is_approved', value)
                }
              />
            </Form.Item>
          )}
          {personForm.id && (
            <Form.Item label={'№ оригинала'}>
              {getFieldDecorator('parent_id', {
                rules: [{ required: false }],
                initialValue: personForm.parent_id,
              })(
                <Select
                  placeholder="Выберите"
                  showSearch
                  onSearch={this.props.searchPeople}
                  filterOption={false}
                  onChange={value =>
                    this.props.setFieldForPerson('parent_id', value)
                  }
                >
                  <Option key={0} value={null}>
                    Не выбрано
                  </Option>
                  {personForm.searchPeopleList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.lastName +
                        ' ' +
                        item.firstName +
                        ' ' +
                        item.thirdName +
                        ' (№' +
                        item.id +
                        ')'}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          )}

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>

        <p>
          <i>* Если нет дня или месяца, то ГГГГ или ГГГГ-ММ</i>
        </p>
      </Spin>
    ) : null
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    mentioned_people: store.mentioned_people,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    createPerson: (
      firstName,
      lastName,
      thirdName,
      nickname,
      sex,
      birthDay,
      deathDay,
      comment,
      is_approved
    ) =>
      dispatch(
        createPerson(
          firstName,
          lastName,
          thirdName,
          nickname,
          sex,
          birthDay,
          deathDay,
          comment,
          is_approved
        )
      ),
    updatePerson: (
      id,
      firstName,
      lastName,
      thirdName,
      nickname,
      sex,
      birthDay,
      deathDay,
      comment,
      is_approved,
      parent_id
    ) =>
      dispatch(
        updatePerson(
          id,
          firstName,
          lastName,
          thirdName,
          nickname,
          sex,
          birthDay,
          deathDay,
          comment,
          is_approved,
          parent_id
        )
      ),
    setFieldForPerson: (field, value) =>
      dispatch(setFieldForPerson(field, value)),
    searchPeople: (search = '') => dispatch(searchPeople(search)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create({ name: 'PersonForm' })(PersonForm))
