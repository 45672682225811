import produce from 'immer'

import {
  USERS_LOAD_REQUEST,
  USERS_LOAD_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  OPEN_EDIT_USER_FORM,
  CLOSE_USER_FORM,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  SET_FIELD_FOR_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  OPEN_USER_FORM,
} from '../actions/UsersActions'

const initialState = {
  usersList: [],
  isLoadingUsersList: false,
  isTotalReached: false,
  animation: false,
  pageNumber: 1,
  isDeletingUser: false,

  userForm: {
    id: null,
    firstname: '',
    lastname: '',
    login: '',
    password: '',
    role: 'volunteer',
    email: '',
    is_send_notifications: false,

    isOpen: false,

    isSending: false,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case USERS_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingUsersList = true
        draftState.isTotalReached = false

        const pageNumber = action.payload.request.params['page']
        if (pageNumber === 1) {
          draftState.usersList = []
        }
        draftState.pageNumber = pageNumber
      })
    case USERS_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingUsersList = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.usersList = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.usersList = draftState.usersList.concat(
            action.payload.data
          )
        }
      })
    case DELETE_USER_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingUser = false
      })
    case DELETE_USER_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingUser = true
        draftState.animation = true

        draftState.usersList.splice(
          draftState.usersList.findIndex(item => item.id === action.payload.id),
          1
        )
      })
    case OPEN_EDIT_USER_FORM:
      return produce(state, draftState => {
        let user = draftState.usersList.find(
          item => item.id === action.payload.id
        )
        draftState.userForm.id = user.id
        draftState.userForm.firstname = user.firstname
        draftState.userForm.lastname = user.lastname
        draftState.userForm.login = user.login
        draftState.userForm.password = ''
        draftState.userForm.role = user.role
        draftState.userForm.email = user.email
        draftState.userForm.is_send_notifications = user.is_send_notifications

        draftState.userForm.isOpen = true
      })
    case CLOSE_USER_FORM:
      return produce(state, draftState => {
        draftState.userForm.isOpen = false
      })
    case CREATE_USER_REQUEST:
      return produce(state, draftState => {
        draftState.userForm.isSending = true
        draftState.animation = true
      })
    case CREATE_USER_SUCCESS:
      return produce(state, draftState => {
        draftState.userForm.isSending = false
        draftState.userForm.isOpen = false
        draftState.usersList.unshift(action.payload.data)
      })
    case SET_FIELD_FOR_USER:
      return produce(state, draftState => {
        draftState.userForm[action.payload.field] = action.payload.value
      })
    case UPDATE_USER_SUCCESS:
      return produce(state, draftState => {
        draftState.userForm.isSending = false
        draftState.userForm.isOpen = false

        let userIndex = draftState.usersList.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.usersList[userIndex] = action.payload.data
      })
    case UPDATE_USER_REQUEST:
      return produce(state, draftState => {
        draftState.userForm.isSending = true
      })
    case OPEN_USER_FORM:
      return produce(state, draftState => {
        draftState.userForm = {
          ...initialState.userForm,
          isOpen: true,
        }
      })
    default:
      return state
  }
}
