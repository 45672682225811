import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import {
  Table,
  Spin,
  Divider,
  Icon,
  Popconfirm,
  Drawer,
  Button,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  message,
} from 'antd'
import { TweenOneGroup } from 'rc-tween-one'
import {
  EditOutlined,
  DeleteOutlined,
  CopyOutlined,
  SearchOutlined,
} from '@ant-design/icons'

import {
  getPeopleList,
  deletePerson,
  openEditPersonForm,
  closePersonForm,
  openPersonForm,
  setSearchFields,
  setDoubleSearch,
  setExactSearch,
} from '../../../redux/actions/MentionedPeopleActions'

import { getUsersList } from '../../../redux/actions/UsersActions'

import PersonForm from './Components/PersonFormComponent'

const { Option } = Select

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0,
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad',
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad',
  },
  { delay: 1000, backgroundColor: '#fff' },
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' },
]

let searchTimer

class MentionedPeople extends PureComponent {
  constructor(props) {
    super(props)

    this.selectText = this.selectText.bind(this)
  }

  componentDidMount() {
    this.props.getPeopleList(1, '', null, {
      ...this.props.mentioned_people.filterForm,
      status: [],
      user_created_id: [],
      lastName: '',
      firstName: '',
      thirdName: '',
      nickname: '',
      comment: '',
    })
    this.props.getUsersList(1, 999)
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (
      this.props.mentioned_people.isLoadingPeopleList ||
      this.props.mentioned_people.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.mentioned_people.pageNumber + 1
      this.props.getPeopleList(
        pageNumber,
        this.props.mentioned_people.search,
        this.props.mentioned_people.sort,
        this.props.mentioned_people.filterForm
      )
    }
  }

  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.mentioned_people.animation ? enterAnim : null}
              leave={this.props.mentioned_people.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  getPersonStatus = approved => {
    if (approved) {
      return (
        <Icon
          type="check-circle"
          className="Users__approved"
          title="Подтверждено"
        />
      )
    } else {
      return (
        <Icon
          type="exclamation"
          className="Users__not-approved"
          title="Не подтверждено"
        />
      )
    }
  }

  getPersonSex = sex => {
    switch (sex) {
      case 1:
        return 'Женский'
      case 2:
        return 'Мужской'
      default:
        return 'Не указано'
    }
  }

  startSearch(e) {
    const value = e.target.value

    clearTimeout(searchTimer)
    searchTimer = setTimeout(() => {
      this.props.getPeopleList(
        1,
        value,
        this.props.mentioned_people.sort,
        this.props.mentioned_people.filterForm
      )
    }, 500)
  }

  selectText(value, dataIndex = '') {
    value = value + ''
    let str = ''

    const search =
      dataIndex && this.props.mentioned_people.filterForm[dataIndex] !== ''
        ? this.props.mentioned_people.filterForm[dataIndex].toLowerCase()
        : this.props.mentioned_people.search.toLowerCase()

    if (search === '' || !value) {
      str = value
    } else {
      var text = value,
        text_lower = value.toLowerCase(),
        index = text_lower.indexOf(search),
        length = search.length

      if (index !== -1) {
        str = (
          <>
            {text.substr(0, index)}
            <span className="Users__selected-text">
              {text.substr(index, length)}
            </span>
            {text.substr(index + length)}
          </>
        )
      } else {
        str = value
      }
    }

    return str
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc',
      }
    }

    let filterForm = {
      ...this.props.mentioned_people.filterForm,
      user_created_id: filters.user_created ? filters.user_created : [],
      status: filters.status ? filters.status : [],
    }

    this.props.getPeopleList(
      1,
      this.props.mentioned_people.search,
      sort,
      filterForm
    )
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder="Поиск..."
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <div>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>{' '}
          <Button
            onClick={() => this.handleReset(clearFilters, dataIndex)}
            size="small"
            style={{ width: 90 }}
          >
            Сбросить
          </Button>
        </div>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color:
            filtered || this.props.mentioned_people.filterForm[dataIndex]
              ? '#ea6645'
              : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
  })

  handleSearch = (selectedKeys, dataIndex) => {
    this.props.getPeopleList(
      1,
      this.props.mentioned_people.search,
      this.props.mentioned_people.sort,
      {
        ...this.props.mentioned_people.filterForm,
        [dataIndex]: selectedKeys[0],
      }
    )
  }

  handleReset = (clearFilters, dataIndex) => {
    clearFilters()
    this.props.getPeopleList(
      1,
      this.props.mentioned_people.search,
      this.props.mentioned_people.sort,
      {
        ...this.props.mentioned_people.filterForm,
        [dataIndex]: '',
      }
    )
  }

  render() {
    const { Search } = Input
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { user, mentioned_people } = this.props

    let additionalTableParams = {}
    if (this.props.mentioned_people.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    const COMMENT_MAX_LENGTH = 170
    const dataSource = mentioned_people.peopleList.map(item => ({
      key: item.id,
      id: this.selectText(item.id),
      firstName: this.selectText(item.firstName, 'firstName'),
      lastName: this.selectText(item.lastName, 'lastName'),
      thirdName: this.selectText(item.thirdName, 'thirdName'),
      nickname: this.selectText(item.nickname, 'nickname'),
      sex: this.getPersonSex(item.sex),
      birthDay: this.selectText(item.birthDay),
      deathDay: this.selectText(item.deathDay),
      comment: this.selectText(
        item.comment.length > COMMENT_MAX_LENGTH
          ? item.comment.substring(COMMENT_MAX_LENGTH, -COMMENT_MAX_LENGTH) +
              '...'
          : item.comment,
        'comment'
      ),
      status: this.getPersonStatus(item.is_approved),
      user_created_id: item.user_created_id,
      user_created:
        item['user_created_data.lastname'] +
        ' ' +
        item['user_created_data.firstname'],
      is_approved: item.is_approved,
      parent_id: item.parent_id ? item.parent_id : 'Да',
    }))

    const columns = [
      {
        title: '№',
        key: 'id',
        render: (text, record) => (
          <>
            {record.id}
            <span className="Users__control-buttons">
              <a
                href="/"
                onClick={e => {
                  e.preventDefault()

                  let area = document.createElement('textarea')
                  document.body.appendChild(area)
                  area.value = record.id
                  area.select()
                  document.execCommand('copy')
                  document.body.removeChild(area)

                  message.success('Номер скопирован')
                }}
                title="Копировать"
              >
                <CopyOutlined />
              </a>
            </span>
          </>
        ),
      },
      {
        title: 'Оригинал',
        dataIndex: 'parent_id',
        key: 'parent_id',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
      },
      {
        title: 'Статус',
        dataIndex: 'status',
        key: 'status',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        filters: [
          {
            text: 'Не подтверждено',
            value: 0,
          },
          {
            text: 'Подтверждено',
            value: 1,
          },
        ],
      },
      {
        title: 'Фамилия',
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        ...this.getColumnSearchProps('lastName'),
      },
      {
        title: 'Имя',
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        ...this.getColumnSearchProps('firstName'),
      },
      {
        title: 'Отчество',
        dataIndex: 'thirdName',
        key: 'thirdName',
        sorter: true,
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        ...this.getColumnSearchProps('thirdName'),
      },
      {
        title: 'Другие имена',
        dataIndex: 'nickname',
        key: 'nickname',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        ...this.getColumnSearchProps('nickname'),
      },
      {
        title: 'Пол',
        dataIndex: 'sex',
        key: 'sex',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
      },
      {
        title: 'Дата рождения',
        dataIndex: 'birthDay',
        key: 'birthDay',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
      },
      {
        title: 'Дата смерти',
        dataIndex: 'deathDay',
        key: 'deathDay',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        ...this.getColumnSearchProps('comment'),
      },
      {
        title: 'Создатель',
        dataIndex: 'user_created',
        key: 'user_created',
        className: 'Users__table-cell',
        onCell: (record, rowIndex) => ({
          onClick: event => {
            this.props.openEditPersonForm(record.key)
          },
        }),
        filters: [
          ...this.props.users.usersList.map(item => ({
            text: item.lastname + ' ' + item.firstname,
            value: item.id,
          })),
        ],
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span className="Users__control-buttons">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.openEditPersonForm(record.key)
              }}
              title="Редактировать"
            >
              <EditOutlined />
            </a>
            {user.attributes.role !== 'volunteer' && (
              <>
                <Divider type="vertical" />
                <Popconfirm
                  title="Вы уверены, что хотите удалить запись?"
                  onConfirm={e => this.props.deletePerson(record.key)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <a href="/" title="Удалить">
                    <DeleteOutlined />
                  </a>
                </Popconfirm>
              </>
            )}
          </span>
        ),
      },
    ]

    const { personForm } = this.props.mentioned_people

    return (
      <div>
        <Helmet>
          <title>Персоны</title>
        </Helmet>

        <h1>
          Персоны
          <Button
            type="primary"
            className="Users__add-button"
            onClick={() =>
              this.props.openPersonForm(
                this.props.user.attributes.role === 'admin'
              )
            }
          >
            Создать
          </Button>
        </h1>

        <Search
          placeholder="Поиск..."
          onChange={this.startSearch.bind(this)}
          enterButton
          size="large"
          className="Users__search"
          style={{ marginBottom: '.5rem' }}
        />

        <Row style={{ marginBottom: '.5rem' }}>
          <Col>
            Искать по
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Выберите"
              onChange={value => {
                this.props.setSearchFields(value)
                this.props.getPeopleList(
                  1,
                  this.props.mentioned_people.search,
                  this.props.mentioned_people.sort,
                  {
                    ...this.props.mentioned_people.filterForm,
                    search_fields: value,
                  }
                )
              }}
            >
              <Option value={'id'}>№</Option>
              <Option value={'lastName'}>Фамилия</Option>
              <Option value={'firstName'}>Имя</Option>
              <Option value={'thirdName'}>Отчество</Option>
              <Option value={'nickname'}>Другие имена</Option>
              <Option value={'comment'}>Комментарий</Option>
            </Select>
          </Col>
        </Row>

        <Row>
          <Col>
            <Checkbox
              checked={mentioned_people.filterForm.is_hide_double}
              onChange={e => {
                this.props.setDoubleSearch(e.target.checked)
                this.props.getPeopleList(
                  1,
                  this.props.mentioned_people.search,
                  this.props.mentioned_people.sort,
                  {
                    ...this.props.mentioned_people.filterForm,
                    is_hide_double: e.target.checked,
                  }
                )
              }}
            >
              Исключить дубли
            </Checkbox>

            <Checkbox
              checked={mentioned_people.filterForm.is_exact_search}
              onChange={e => {
                this.props.setExactSearch(e.target.checked)
                this.props.getPeopleList(
                  1,
                  this.props.mentioned_people.search,
                  this.props.mentioned_people.sort,
                  {
                    ...this.props.mentioned_people.filterForm,
                    is_exact_search: e.target.checked,
                  }
                )
              }}
            >
              Точный поиск
            </Checkbox>
          </Col>
        </Row>

        <TableContext.Provider>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onChange={this.handleTableChange}
            //rowClassName="Users__table-row"
            //style={tableStyle}
            {...additionalTableParams}
          />
        </TableContext.Provider>

        {this.props.mentioned_people.isLoadingPeopleList && (
          <div className="Users__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={personForm.id ? 'Редактирование' : 'Создание'}
          width={600}
          onClose={this.props.closePersonForm}
          visible={personForm.isOpen}
        >
          <PersonForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    users: store.users,
    mentioned_people: store.mentioned_people,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getPeopleList: (
      pageNumber = 1,
      search = '',
      sort = null,
      filterForm = null
    ) => dispatch(getPeopleList(pageNumber, search, sort, filterForm)),
    deletePerson: id => dispatch(deletePerson(id)),
    openEditPersonForm: id => dispatch(openEditPersonForm(id)),
    closePersonForm: () => dispatch(closePersonForm()),
    openPersonForm: is_approved => dispatch(openPersonForm(is_approved)),
    getUsersList: (pageNumber = 1, page_size = null) =>
      dispatch(getUsersList(pageNumber, page_size)),
    setSearchFields: fields => dispatch(setSearchFields(fields)),
    setDoubleSearch: value => dispatch(setDoubleSearch(value)),
    setExactSearch: value => dispatch(setExactSearch(value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MentionedPeople)
