import React, { PureComponent } from 'react'
import { Row, Col, Button, message, InputNumber } from 'antd'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import NoSleep from 'nosleep.js'

import {
  initConverterForm,
  runConverterRequest,
  runConverterSuccess,
  finishConverterOne,
  updateSettingsConverterForm,
} from '../../../../redux/actions/FormsActions'

import { writeConsole } from '../../../../redux/actions/FilesActions'

const noSleep = new NoSleep()

const socket = io(`${process.env.REACT_APP_SOCKET_HOST}/`, {
  rejectUnauthorized: false,
})

class Form extends PureComponent {
  componentDidMount() {
    this.props.initConverterForm()
    this.socketFunc()
  }

  socketFunc = () => {
    socket.connect()

    socket.on('connect_error', function(error) {
      console.log('connect_error', error)
    })

    socket.on('connect', function() {})

    // one finished
    socket.on('fileConverterFinished', uid => {
      this.props.finishConverterOne(uid)
    })

    // new log
    socket.on('fileConverterLog', log => {
      this.props.writeConsole(log, 'info')
    })

    // new warning
    socket.on('fileConverterWarning', log => {
      this.props.writeConsole(log, 'warning')
    })

    // processing finished
    socket.on('runConverterRes', uids => {
      this.props.runConverterSuccess(uids)
      noSleep.disable()
    })

    // processing error
    socket.on('runConverterError', error => {
      message.error(error.message)
      noSleep.disable()
    })
  }

  onParse() {
    const files = this.props.files.files
      .filter(item => item.status === 'uploaded')
      .map(item => item.uid)
    const converterForm = this.props.forms.converter

    if (files.length === 0) {
      message.error('Загрузите по крайней мере один файл для конвертации')
    } else if (files.length > 10) {
      message.error(
        'За один раз можно конвертировать не более десяти файлов. Удалите лишние файлы и загрузите их перед следующим конвертированием'
      )
    } else {
      socket.emit('runConverter', {
        uuids: files,
        num_persons_with: converterForm.num_persons_with,
        user_id: this.props.user.id,
      })
      this.props.runConverterRequest(files)
      noSleep.enable()
    }
  }

  render() {
    const converterForm = this.props.forms.converter

    return (
      <Row gutter={16}>
        <Col span={22}>
          <Row className={'Form__container'}>
            <Col>
              Нумерация персон с
              <InputNumber
                min={1}
                value={converterForm.num_persons_with}
                className={'Form__numberInput'}
                onChange={value =>
                  this.props.updateSettingsConverterForm(
                    'num_persons_with',
                    value
                  )
                }
              />
            </Col>
          </Row>

          <Button
            block
            type="primary"
            className={'Form__container'}
            style={{ marginBottom: '3rem' }}
            loading={converterForm.isLoading}
            onClick={e => this.onParse()}
          >
            Конвертировать
          </Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.files,
    forms: store.forms,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initConverterForm: () => dispatch(initConverterForm()),
    runConverterRequest: uids => dispatch(runConverterRequest(uids)),
    runConverterSuccess: uids => dispatch(runConverterSuccess(uids)),
    writeConsole: (value, type = 'info') => dispatch(writeConsole(value, type)),
    finishConverterOne: uid => dispatch(finishConverterOne(uid)),
    updateSettingsConverterForm: (param, value) =>
      dispatch(updateSettingsConverterForm(param, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
