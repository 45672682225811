import React, { PureComponent } from 'react'
import { Row, Col, Table, Popconfirm, Spin } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DownloadForm from './Components/DownloadFormComponent'

import { removeFile } from '../../redux/actions/FilesActions'

class DocumentsList extends PureComponent {
  render() {
    const url = process.env.REACT_APP_API_HOST

    const columnsQueries = [
      {
        title: '№',
        dataIndex: 'count',
        key: 'count',
        width: 50,
      },
      {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Статус',
        key: 'status',
        width: 180,
        render: (text, record) => {
          switch (record.status) {
            case 'uploading':
              return (
                <>
                  загружается{' '}
                  <Spin size="small" style={{ marginLeft: '3px' }} />
                </>
              )
            case 'uploaded':
              return <>загружен</>
            case 'processing':
              return (
                <>
                  обрабатывается{' '}
                  <Spin size="small" style={{ marginLeft: '3px' }} />
                </>
              )
            case 'processed':
              return <>обработан</>
            default:
              break
          }
        },
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span>
            {record.status === 'processed' && (
              <a
                href={`${url}/file/download/${
                  record.uid
                }?name=${record.name
                  .replace('.docx', '')
                  .replace('.zip', '')}&format=${
                  record.name.search('.docx') > 0 ? 'docx' : 'xml'
                }`}
                style={{ marginRight: '10px' }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Скачать
              </a>
            )}

            {(record.status === 'uploaded' ||
              record.status === 'processed') && (
              <Popconfirm
                title="Вы уверены, что хотите удалить файл?"
                onConfirm={e => this.props.removeFile(record.uid)}
                okText="Да"
                cancelText="Нет"
              >
                <a href="/">Удалить</a>
              </Popconfirm>
            )}
          </span>
        ),
      },
    ]

    const dataQueries = this.props.files.files.map((item, index) => ({
      key: item.uid,
      uid: item.uid,
      count: index + 1,
      name: item.name,
      status: item.status,
    }))

    return (
      <>
        <Row gutter={16}>
          <Col span={22}>
            <DownloadForm
              allowTypes={this.props.allowTypes}
              hint={this.props.hint ? this.props.hint : null}
            />

            <Table
              columns={columnsQueries}
              dataSource={dataQueries}
              pagination={false}
              className={'DocumentsList__files-table'}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.files,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeFile: uid => dispatch(removeFile(uid)),
  }
}

DocumentsList.propTypes = {
  allowTypes: PropTypes.array,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsList)
