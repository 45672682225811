import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Collapse, Badge, Tooltip } from 'antd'

import { clearConsole } from '../../redux/actions/FilesActions'

const { Panel } = Collapse

class Console extends PureComponent {
  componentDidMount() {
    this.props.clearConsole()
  }
  render() {
    const consoleData = this.props.files.console
    return (
      <Row gutter={16}>
        <Col span={22}>
          <Collapse style={{ marginBottom: '2rem' }}>
            <Panel
              header={
                <>
                  Консоль{' '}
                  <Tooltip placement="top" title="Логи">
                    <Badge
                      count={consoleData.infoCounter}
                      className={'Console__badge'}
                    />
                  </Tooltip>
                  <Tooltip placement="top" title="Предупреждения">
                    <Badge
                      count={consoleData.warningsCounter}
                      className={'Console__badge Console__badge_warning'}
                    />
                  </Tooltip>
                </>
              }
              key="1"
            >
              {consoleData.messages.length > 0 &&
                consoleData.messages.map(item => (
                  <p
                    key={item.key}
                    className={
                      item.type === 'info'
                        ? 'Console-info-log'
                        : 'Console-warning-log'
                    }
                  >
                    $ {item.value}
                  </p>
                ))}
            </Panel>
          </Collapse>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.files,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearConsole: () => dispatch(clearConsole()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Console)
