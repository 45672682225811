import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './redux/configureStore'
import App from './containers/App/AppComponent'
import { ConfigProvider } from 'antd'
import ruRU from 'antd/es/locale/ru_RU'

import './styles/generated.css'

const target = document.getElementById('root')

render(
  <Provider store={store}>
    <ConfigProvider locale={ruRU}>
      <ConnectedRouter history={history}>
        <div>
          <App />
        </div>
      </ConnectedRouter>
    </ConfigProvider>
  </Provider>,
  target
)
