import { combineReducers } from 'redux'
import user from './user'
import server from './server'
import forms from './forms'
import files from './files'
import users from './users'
import mentioned_people from './mentioned_people'

export default combineReducers({
  user,
  server,
  forms,
  files,
  users,
  mentioned_people,
})
