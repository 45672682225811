export const CLEAR_CONSOLE = 'FILES/CLEAR_CONSOLE'

export function clearConsole() {
  return dispatch => {
    dispatch({
      type: CLEAR_CONSOLE,
    })
  }
}

export const WRITE_CONSOLE = 'FILES/WRITE_CONSOLE'

export function writeConsole(value, type = 'info') {
  return dispatch => {
    dispatch({
      type: WRITE_CONSOLE,
      payload: {
        value,
        type,
      },
    })
  }
}

export const ADD_FILE = 'FILES/ADD_FILE'

export function addFile(uid, name) {
  return dispatch => {
    dispatch({
      type: ADD_FILE,
      payload: {
        uid,
        name,
      },
    })
  }
}

export const REMOVE_FILE = 'FILES/REMOVE_FILE'

export function removeFile(uid) {
  return dispatch => {
    dispatch({
      type: REMOVE_FILE,
      payload: {
        uid,
      },
    })
  }
}

export const UPLOAD_FILE = 'FILES/UPLOAD_FILE'

export function uploadFile(uid) {
  return dispatch => {
    dispatch({
      type: UPLOAD_FILE,
      payload: {
        uid,
      },
    })
  }
}
