import React from 'react'
import { Helmet } from 'react-helmet'
import { Row, Col } from 'antd'

import Form from './Components/FormComponent'
import DocumentsList from '../../DocumentsList/DocumentsListComponent'
import Console from '../../Console/ConsoleComponent'

const Converter = props => (
  <div>
    <Helmet>
      <title>TEI-конвертер</title>
    </Helmet>

    <h1>TEI-конвертер</h1>

    <Row>
      <Col xs={24} sm={24} md={24} lg={9} xl={9}>
        <Form />
        <Console />
      </Col>
      <Col xs={24} sm={24} md={24} lg={15} xl={15}>
        <DocumentsList
          allowTypes={['zip']}
          hint="Загрузите zip-архив с файлами: meta.xlsx, persons.xlsx, start.docx"
        />
      </Col>
    </Row>
  </div>
)

export default Converter
