import React, { PureComponent } from 'react'
import { Row, Col } from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import Form from './Components/FormComponent'
import DocumentsList from '../../DocumentsList/DocumentsListComponent'
import Console from '../../Console/ConsoleComponent'

class Parser extends PureComponent {
  render() {
    return (
      <div>
        <Helmet>
          <title>Парсер</title>
        </Helmet>

        <h1>Парсер</h1>

        <Row>
          <Col xs={24} sm={24} md={24} lg={9} xl={9}>
            <Form />
            <Console />
          </Col>
          <Col xs={24} sm={24} md={24} lg={15} xl={15}>
            <DocumentsList allowTypes={['docx']} />
          </Col>
        </Row>
      </div>
    )
  }
}

export default connect()(Parser)
