import produce from 'immer'
import {
  INIT_1P_PARSER_FORM,
  UPDATE_SETTINGS_1P_PARSER_FORM,
  PARSER_1P_REQUEST,
  PARSER_1P_SUCCESS,
  INIT_1P_CLEANER_FORM,
  CLEANER_1P_REQUEST,
  CLEANER_1P_SUCCESS,
  INIT_CONVERTER_FORM,
  CONVERTER_REQUEST,
  CONVERTER_SUCCESS,
  UPDATE_SETTINGS_CONVERTER_FORM,
  INIT_1P_CONVERTER_FORM,
  CONVERTER_1P_REQUEST,
  CONVERTER_1P_SUCCESS,
} from '../actions/FormsActions'

const initialState = {
  __persist: true,
  parser_1p: {
    select_names: true,
    select_dates_and_places: true,
    simple_markup: false,
    type: 'авт',
    default_place: '',
    isLoading: false,
  },
  cleaner_1p: {
    isLoading: false,
  },
  converter: {
    isLoading: false,
    num_persons_with: 2,
  },
  converter_1p: {
    isLoading: false,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case PARSER_1P_SUCCESS:
      return produce(state, draftState => {
        draftState.parser_1p.isLoading = false
      })
    case PARSER_1P_REQUEST:
      return produce(state, draftState => {
        draftState.parser_1p.isLoading = true
      })
    case UPDATE_SETTINGS_1P_PARSER_FORM:
      return produce(state, draftState => {
        draftState.parser_1p[action.payload.param] = action.payload.value
      })
    case INIT_1P_PARSER_FORM:
      return produce(state, draftState => {
        draftState.parser_1p.isLoading = false
      })
    case INIT_1P_CLEANER_FORM:
      return produce(state, draftState => {
        draftState.cleaner_1p.isLoading = false
      })
    case INIT_CONVERTER_FORM:
      return produce(state, draftState => {
        draftState.converter.isLoading = false
      })
    case INIT_1P_CONVERTER_FORM:
      return produce(state, draftState => {
        draftState.converter_1p.isLoading = false
      })
    case CONVERTER_1P_SUCCESS:
      return produce(state, draftState => {
        draftState.converter_1p.isLoading = false
      })
    case CONVERTER_1P_REQUEST:
      return produce(state, draftState => {
        draftState.converter_1p.isLoading = true
      })
    case CLEANER_1P_SUCCESS:
      return produce(state, draftState => {
        draftState.cleaner_1p.isLoading = false
      })
    case CLEANER_1P_REQUEST:
      return produce(state, draftState => {
        draftState.cleaner_1p.isLoading = true
      })
    case CONVERTER_SUCCESS:
      return produce(state, draftState => {
        draftState.converter.isLoading = false
      })
    case CONVERTER_REQUEST:
      return produce(state, draftState => {
        draftState.converter.isLoading = true
      })
    case UPDATE_SETTINGS_CONVERTER_FORM:
      return produce(state, draftState => {
        draftState.converter[action.payload.param] = action.payload.value
      })
    default:
      return state
  }
}
