import React, { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { Table, Spin, Divider, Icon, Popconfirm, Drawer, Button } from 'antd'
import { TweenOneGroup } from 'rc-tween-one'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'

import {
  getUsersList,
  deleteUser,
  openEditUserForm,
  closeUserForm,
  openUserForm,
} from '../../../redux/actions/UsersActions'

import UserForm from './Components/UserFormComponent'

const TableContext = React.createContext(false)

const enterAnim = [
  {
    opacity: 0,
    x: 30,
    backgroundColor: '#fffeee',
    duration: 0,
  },
  {
    height: 0,
    duration: 200,
    type: 'from',
    delay: 250,
    ease: 'easeOutQuad',
  },
  {
    opacity: 1,
    x: 0,
    duration: 250,
    ease: 'easeOutQuad',
  },
  { delay: 1000, backgroundColor: '#fff' },
]

const leaveAnim = [
  { duration: 250, opacity: 0 },
  { height: 0, duration: 200, ease: 'easeOutQuad' },
]

class Users extends PureComponent {
  componentDidMount() {
    this.props.getUsersList()
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (this.props.users.isLoadingDuties || this.props.users.isTotalReached) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const pageNumber = this.props.users.pageNumber + 1
      this.props.getUsersList(pageNumber)
    }
  }

  animTag = $props => {
    return (
      <TableContext.Consumer>
        {() => {
          return (
            <TweenOneGroup
              component="tbody"
              enter={this.props.users.animation ? enterAnim : null}
              leave={this.props.users.animation ? leaveAnim : null}
              appear={false}
              exclusive
              {...$props}
            />
          )
        }}
      </TableContext.Consumer>
    )
  }

  render() {
    const antIcon = <Icon type="loading" style={{ fontSize: 40 }} spin />

    const { user, users } = this.props

    if (user.attributes.role !== 'admin') {
      return (
        <div>
          <Helmet>
            <title>Пользователи</title>
          </Helmet>

          <h1>Пользователи</h1>

          <p>Этот раздел недоступен</p>
        </div>
      )
    }

    const dataSource = users.usersList.map(item => ({
      key: item.id,
      firstname: item.firstname,
      lastname: item.lastname,
      login: item.login,
      role: item.role === 'admin' ? 'Админ' : 'Волонтер',
      email: item.email,
      is_send_notifications: item.is_send_notifications ? 'Да' : 'Нет',
    }))

    const columns = [
      {
        title: 'Имя',
        dataIndex: 'firstname',
        key: 'firstname',
      },
      {
        title: 'Фамилия',
        dataIndex: 'lastname',
        key: 'lastname',
      },
      {
        title: 'Логин',
        dataIndex: 'login',
        key: 'login',
      },
      {
        title: 'Роль',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Оповещения',
        dataIndex: 'is_send_notifications',
        key: 'is_send_notifications',
      },
      {
        title: '',
        key: 'action',
        render: (text, record) => (
          <span className="Users__control-buttons">
            <a
              href="/"
              onClick={e => {
                e.preventDefault()
                this.props.openEditUserForm(record.key)
              }}
              title="Редактировать"
            >
              <EditOutlined />
            </a>
            <Divider type="vertical" />
            <Popconfirm
              title="Вы уверены, что хотите удалить запись?"
              onConfirm={e => this.props.deleteUser(record.key)}
              okText="Да"
              cancelText="Нет"
            >
              <a href="/" title="Удалить">
                <DeleteOutlined />
              </a>
            </Popconfirm>
          </span>
        ),
      },
    ]

    let additionalTableParams = {}
    if (this.props.users.animation) {
      additionalTableParams.components = { body: { wrapper: this.animTag } }
    }

    const { userForm } = this.props.users

    return (
      <div>
        <Helmet>
          <title>Пользователи</title>
        </Helmet>

        <h1>
          Пользователи
          <Button
            type="primary"
            className="Users__add-button"
            onClick={this.props.openUserForm}
          >
            Создать
          </Button>
        </h1>

        <TableContext.Provider>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            //onChange={this.handleTableChange}
            //rowClassName="Activity__table-row"
            //style={tableStyle}
            {...additionalTableParams}
          />
        </TableContext.Provider>

        {this.props.users.isLoadingUsersList && (
          <div className="Users__loader">
            <Spin indicator={antIcon} />
          </div>
        )}

        <Drawer
          title={userForm.id ? 'Редактирование' : 'Создание'}
          width={600}
          onClose={this.props.closeUserForm}
          visible={userForm.isOpen}
        >
          <UserForm />
        </Drawer>
      </div>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    users: store.users,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsersList: (pageNumber = 1) => dispatch(getUsersList(pageNumber)),
    deleteUser: id => dispatch(deleteUser(id)),
    openEditUserForm: id => dispatch(openEditUserForm(id)),
    closeUserForm: () => dispatch(closeUserForm()),
    openUserForm: () => dispatch(openUserForm()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
