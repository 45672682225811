export const INIT_1P_PARSER_FORM = 'FORMS/INIT_1P_PARSER_FORM'

export function init1pParserForm() {
  return dispatch => {
    dispatch({
      type: INIT_1P_PARSER_FORM,
    })
  }
}

export const UPDATE_SETTINGS_1P_PARSER_FORM =
  'FORMS/UPDATE_SETTINGS_1P_PARSER_FORM'

export function updateSettings1pParserForm(param, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_SETTINGS_1P_PARSER_FORM,
      payload: {
        param,
        value,
      },
    })
  }
}

export const PARSER_1P_REQUEST = 'FORMS/PARSER_1P_REQUEST'

export function run1pParserRequest(uids) {
  return dispatch => {
    dispatch({
      type: PARSER_1P_REQUEST,
      payload: {
        uids,
      },
    })
  }
}

export const PARSER_1P_SUCCESS = 'FORMS/PARSER_1P_SUCCESS'

export function run1pParserSuccess(uids) {
  return dispatch => {
    dispatch({
      type: PARSER_1P_SUCCESS,
      payload: {
        uids,
      },
    })
  }
}

export const PARSER_1P_ONE_FINISHED = 'FORMS/PARSER_1P_ONE_FINISHED'

export function finish1pParserOne(uid) {
  return dispatch => {
    dispatch({
      type: PARSER_1P_ONE_FINISHED,
      payload: {
        uid,
      },
    })
  }
}

// CLEANER START

export const CLEANER_1P_REQUEST = 'FORMS/CLEANER_1P_REQUEST'

export function run1pCleanerRequest(uids) {
  return dispatch => {
    dispatch({
      type: CLEANER_1P_REQUEST,
      payload: {
        uids,
      },
    })
  }
}

export const CLEANER_1P_SUCCESS = 'FORMS/CLEANER_1P_SUCCESS'

export function run1pCleanerSuccess(uids) {
  return dispatch => {
    dispatch({
      type: CLEANER_1P_SUCCESS,
      payload: {
        uids,
      },
    })
  }
}

export const CLEANER_1P_ONE_FINISHED = 'FORMS/CLEANER_1P_ONE_FINISHED'

export function finish1pCleanerOne(uid) {
  return dispatch => {
    dispatch({
      type: CLEANER_1P_ONE_FINISHED,
      payload: {
        uid,
      },
    })
  }
}

export const INIT_1P_CLEANER_FORM = 'FORMS/INIT_1P_CLEANER_FORM'

export function init1pCleanerForm() {
  return dispatch => {
    dispatch({
      type: INIT_1P_CLEANER_FORM,
    })
  }
}

// CLEANER END

// CONVERTER START

export const INIT_CONVERTER_FORM = 'FORMS/INIT_CONVERTER_FORM'

export function initConverterForm() {
  return dispatch => {
    dispatch({
      type: INIT_CONVERTER_FORM,
    })
  }
}

export const CONVERTER_REQUEST = 'FORMS/CONVERTER_REQUEST'

export function runConverterRequest(uids) {
  return dispatch => {
    dispatch({
      type: CONVERTER_REQUEST,
      payload: {
        uids,
      },
    })
  }
}

export const CONVERTER_SUCCESS = 'FORMS/CONVERTER_SUCCESS'

export function runConverterSuccess(uids) {
  return dispatch => {
    dispatch({
      type: CONVERTER_SUCCESS,
      payload: {
        uids,
      },
    })
  }
}

export const CONVERTER_ONE_FINISHED = 'FORMS/CONVERTER_ONE_FINISHED'

export function finishConverterOne(uid) {
  return dispatch => {
    dispatch({
      type: CONVERTER_ONE_FINISHED,
      payload: {
        uid,
      },
    })
  }
}

export const UPDATE_SETTINGS_CONVERTER_FORM =
  'FORMS/UPDATE_SETTINGS_CONVERTER_FORM'

export function updateSettingsConverterForm(param, value) {
  return dispatch => {
    dispatch({
      type: UPDATE_SETTINGS_CONVERTER_FORM,
      payload: {
        param,
        value,
      },
    })
  }
}
// CONVERTER END

// 1P CONVERTER START

export const CONVERTER_1P_REQUEST = 'FORMS/CONVERTER_1P_REQUEST'

export function run1pConverterRequest(uids) {
  return dispatch => {
    dispatch({
      type: CONVERTER_1P_REQUEST,
      payload: {
        uids,
      },
    })
  }
}

export const CONVERTER_1P_SUCCESS = 'FORMS/CONVERTER_1P_SUCCESS'

export function run1pConverterSuccess(uids) {
  return dispatch => {
    dispatch({
      type: CONVERTER_1P_SUCCESS,
      payload: {
        uids,
      },
    })
  }
}

export const CONVERTER_1P_ONE_FINISHED = 'FORMS/CONVERTER_1P_ONE_FINISHED'

export function finish1pConverterOne(uid) {
  return dispatch => {
    dispatch({
      type: CONVERTER_1P_ONE_FINISHED,
      payload: {
        uid,
      },
    })
  }
}

export const INIT_1P_CONVERTER_FORM = 'FORMS/INIT_1P_CONVERTER_FORM'

export function init1pConverterForm() {
  return dispatch => {
    dispatch({
      type: INIT_1P_CONVERTER_FORM,
    })
  }
}

// 1P CONVERTER END
