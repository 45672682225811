import React, { PureComponent } from 'react'
import { Row, Col, Button, message } from 'antd'
import { connect } from 'react-redux'
import io from 'socket.io-client'
import NoSleep from 'nosleep.js'

import {
  init1pCleanerForm,
  run1pCleanerRequest,
  run1pCleanerSuccess,
  finish1pCleanerOne,
} from '../../../../redux/actions/FormsActions'

import { writeConsole } from '../../../../redux/actions/FilesActions'

const noSleep = new NoSleep()

const socket = io(`${process.env.REACT_APP_SOCKET_HOST}/`, {
  rejectUnauthorized: false,
})

class Form extends PureComponent {
  componentDidMount() {
    this.props.init1pCleanerForm()
    this.socketFunc()
  }

  socketFunc = () => {
    socket.connect()

    socket.on('connect_error', function(error) {
      console.log('connect_error', error)
    })

    socket.on('connect', function() {})

    // one finished
    socket.on('fileCleaning1pFinished', uid => {
      this.props.finish1pCleanerOne(uid)
    })

    // new log
    socket.on('fileCleaning1pLog', log => {
      this.props.writeConsole(log, 'info')
    })

    // new warning
    socket.on('fileCleaning1pWarning', log => {
      this.props.writeConsole(log, 'warning')
    })

    // processing finished
    socket.on('runCleaning1pRes', uids => {
      this.props.run1pCleanerSuccess(uids)
      noSleep.disable()
    })

    // processing error
    socket.on('runCleaning1pError', error => {
      message.error(error.message)
      noSleep.disable()
    })
  }

  onParse() {
    const files = this.props.files.files
      .filter(item => item.status === 'uploaded')
      .map(item => item.uid)

    if (files.length === 0) {
      message.error('Загрузите по крайней мере один файл для обработки')
    } else if (files.length > 10) {
      message.error(
        'За один раз можно обработать не более десяти файлов. Удалите лишние файлы и загрузите их перед следующей загрузкой'
      )
    } else {
      socket.emit('runCleaning1p', {
        uuids: files,
      })
      this.props.run1pCleanerRequest(files)
      noSleep.enable()
    }
  }

  render() {
    const cleanerForm = this.props.forms.cleaner_1p

    return (
      <Row gutter={16}>
        <Col span={22}>
          <Button
            block
            type="primary"
            className={'Form__container'}
            style={{ marginBottom: '3rem' }}
            loading={cleanerForm.isLoading}
            onClick={e => this.onParse()}
          >
            Очистить
          </Button>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = store => {
  return {
    files: store.files,
    forms: store.forms,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init1pCleanerForm: () => dispatch(init1pCleanerForm()),
    run1pCleanerRequest: uids => dispatch(run1pCleanerRequest(uids)),
    run1pCleanerSuccess: uids => dispatch(run1pCleanerSuccess(uids)),
    writeConsole: (value, type = 'info') => dispatch(writeConsole(value, type)),
    finish1pCleanerOne: uid => dispatch(finish1pCleanerOne(uid)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
