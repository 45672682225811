import produce from 'immer'

import {
  PEOPLE_LOAD_REQUEST,
  PEOPLE_LOAD_SUCCESS,
  DELETE_PERSON_REQUEST,
  DELETE_PERSON_SUCCESS,
  OPEN_PERSON_FORM,
  OPEN_EDIT_PERSON_FORM,
  CLOSE_PERSON_FORM,
  CREATE_PERSON_REQUEST,
  CREATE_PERSON_SUCCESS,
  UPDATE_PERSON_REQUEST,
  UPDATE_PERSON_SUCCESS,
  SET_FIELD_FOR_PERSON,
  PEOPLE_SEARCH_REQUEST,
  PEOPLE_SEARCH_SUCCESS,
  SET_SEARCH_FIELDS,
  SET_DOUBLE_SEARCH,
  SET_EXACT_SEARCH,
} from '../actions/MentionedPeopleActions'

const initialState = {
  peopleList: [],
  isLoadingPeopleList: false,
  isTotalReached: false,
  animation: false,
  pageNumber: 1,
  search: '',
  sort: null,
  filterForm: {
    user_created_id: [],
    search_fields: [],
    is_hide_double: true,
    is_exact_search: false,
    status: [],
    lastName: '',
    firstName: '',
    thirdName: '',
    nickname: '',
    comment: '',
  },
  isDeletingPerson: false,

  personForm: {
    id: null,
    firstName: '',
    lastName: '',
    thirdName: '',
    nickname: '',
    sex: 0,
    birthDay: '',
    deathDay: '',
    comment: '',
    is_approved: false,
    parent_id: null,

    searchPeopleList: [],
    isPeopleSearch: false,

    isOpen: false,

    isSending: false,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_DOUBLE_SEARCH:
      return produce(state, draftState => {
        draftState.filterForm.is_hide_double = action.payload.value
      })
    case SET_EXACT_SEARCH:
      return produce(state, draftState => {
        draftState.filterForm.is_exact_search = action.payload.value
      })
    case SET_SEARCH_FIELDS:
      return produce(state, draftState => {
        draftState.filterForm.search_fields = action.payload.fields
      })
    case PEOPLE_SEARCH_REQUEST:
      return produce(state, draftState => {
        draftState.personForm.isPeopleSearch = true
      })
    case PEOPLE_SEARCH_SUCCESS:
      return produce(state, draftState => {
        draftState.personForm.isPeopleSearch = false
        draftState.personForm.searchPeopleList = action.payload.data
      })
    case PEOPLE_LOAD_REQUEST:
      return produce(state, draftState => {
        draftState.isLoadingPeopleList = true
        draftState.isTotalReached = false

        const requestParams = action.payload.request.params

        const pageNumber = requestParams['page']
        if (pageNumber === 1) {
          draftState.peopleList = []
        }
        draftState.pageNumber = pageNumber
        draftState.search = requestParams['search']

        if (requestParams['sort']) {
          draftState.sort = {
            field: requestParams['sort'],
            type: requestParams['sort_type'],
          }
        } else {
          draftState.sort = null
        }

        if (requestParams['user_created_id']) {
          draftState.filterForm.user_created_id =
            requestParams['user_created_id']
        } else {
          draftState.filterForm.user_created_id = []
        }

        if (requestParams['status']) {
          draftState.filterForm.status = requestParams['status']
        } else {
          draftState.filterForm.status = []
        }

        draftState.filterForm.lastName = requestParams['lastName']
          ? requestParams['lastName']
          : ''
        draftState.filterForm.firstName = requestParams['firstName']
          ? requestParams['firstName']
          : ''
        draftState.filterForm.thirdName = requestParams['thirdName']
          ? requestParams['thirdName']
          : ''
        draftState.filterForm.nickname = requestParams['nickname']
          ? requestParams['nickname']
          : ''
        draftState.filterForm.comment = requestParams['comment']
          ? requestParams['comment']
          : ''
      })
    case PEOPLE_LOAD_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoadingPeopleList = false
        draftState.animation = false

        if (draftState.pageNumber === 1) {
          draftState.peopleList = action.payload.data
        } else {
          draftState.isTotalReached = action.payload.data.length === 0
          draftState.peopleList = draftState.peopleList.concat(
            action.payload.data
          )
        }
      })
    case DELETE_PERSON_SUCCESS:
      return produce(state, draftState => {
        draftState.isDeletingPerson = false
      })
    case DELETE_PERSON_REQUEST:
      return produce(state, draftState => {
        draftState.isDeletingPerson = true
        draftState.animation = true

        draftState.peopleList.splice(
          draftState.peopleList.findIndex(
            item => item.id === action.payload.id
          ),
          1
        )
      })
    case OPEN_PERSON_FORM:
      return produce(state, draftState => {
        draftState.personForm = {
          ...initialState.personForm,
          isOpen: true,
          is_approved: action.payload.is_approved,
        }
      })
    case OPEN_EDIT_PERSON_FORM:
      return produce(state, draftState => {
        let user = draftState.peopleList.find(
          item => item.id === action.payload.id
        )
        draftState.personForm.id = user.id
        draftState.personForm.firstName = user.firstName
        draftState.personForm.lastName = user.lastName
        draftState.personForm.thirdName = user.thirdName
        draftState.personForm.nickname = user.nickname
        draftState.personForm.sex = user.sex
        draftState.personForm.birthDay = user.birthDay
        draftState.personForm.deathDay = user.deathDay
        draftState.personForm.comment = user.comment
        draftState.personForm.is_approved = user.is_approved
        draftState.personForm.parent_id = user.parent_id

        draftState.personForm.isOpen = true
      })
    case CLOSE_PERSON_FORM:
      return produce(state, draftState => {
        draftState.personForm.isOpen = false
      })
    case CREATE_PERSON_REQUEST:
      return produce(state, draftState => {
        draftState.personForm.isSending = true
        draftState.animation = true
      })
    case CREATE_PERSON_SUCCESS:
      return produce(state, draftState => {
        draftState.personForm.isSending = false
        draftState.personForm.isOpen = false
        draftState.peopleList.unshift(action.payload.data)
      })
    case UPDATE_PERSON_SUCCESS:
      return produce(state, draftState => {
        draftState.personForm.isSending = false
        draftState.personForm.isOpen = false

        let personIndex = draftState.peopleList.findIndex(
          item => item.id === action.payload.data.id
        )
        draftState.peopleList[personIndex] = action.payload.data
      })
    case UPDATE_PERSON_REQUEST:
      return produce(state, draftState => {
        draftState.personForm.isSending = true
      })
    case SET_FIELD_FOR_PERSON:
      return produce(state, draftState => {
        draftState.personForm[action.payload.field] = action.payload.value
      })
    default:
      return state
  }
}
