export const USERS_LOAD_REQUEST = 'USERS/USERS_LOAD_REQUEST'
export const USERS_LOAD_SUCCESS = 'USERS/USERS_LOAD_SUCCESS'

export function getUsersList(pageNumber = 1, page_size = null) {
  return dispatch => {
    dispatch({
      types: [USERS_LOAD_REQUEST, USERS_LOAD_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: '/users/get-all',
          params: {
            page: pageNumber,
            page_size,
          },
        },
      },
    })
  }
}

export const DELETE_USER_REQUEST = 'USERS/DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'USERS/DELETE_USER_SUCCESS'

export function deleteUser(id) {
  return dispatch => {
    dispatch({
      types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url: `/users/delete/${id}`,
        },
        id,
      },
    })
  }
}

// USER FROM START
export const OPEN_USER_FORM = 'USERS/OPEN_USER_FORM'

export function openUserForm() {
  return dispatch => {
    dispatch({
      type: OPEN_USER_FORM,
    })
  }
}

export const OPEN_EDIT_USER_FORM = 'USERS/OPEN_EDIT_USER_FORM'

export function openEditUserForm(id) {
  return dispatch => {
    dispatch({
      type: OPEN_EDIT_USER_FORM,
      payload: {
        id,
      },
    })
  }
}

export const CLOSE_USER_FORM = 'USERS/CLOSE_USER_FORM'

export function closeUserForm() {
  return dispatch => {
    dispatch({
      type: CLOSE_USER_FORM,
    })
  }
}

export const CREATE_USER_REQUEST = 'USERS/CREATE_USER_REQUEST'
export const CREATE_USER_SUCCESS = 'USERS/CREATE_USER_SUCCESS'

export function createUser(
  firstname,
  lastname,
  login,
  password,
  role,
  email,
  is_send_notifications
) {
  return dispatch => {
    dispatch({
      types: [CREATE_USER_REQUEST, CREATE_USER_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: '/users/create',
          data: {
            firstname,
            lastname,
            login,
            password,
            role,
            email,
            is_send_notifications,
          },
        },
      },
    })
  }
}

export const UPDATE_USER_REQUEST = 'USERS/UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'USERS/UPDATE_USER_SUCCESS'

export function updateUser(
  id,
  firstname,
  lastname,
  login,
  password,
  role,
  email,
  is_send_notifications
) {
  let additionalParams = {}

  if (password) {
    additionalParams.password = password
  }

  return dispatch => {
    dispatch({
      types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url: '/users/update/' + id,
          data: {
            firstname,
            lastname,
            login,
            role,
            email,
            is_send_notifications,
            ...additionalParams,
          },
        },
      },
    })
  }
}

export const SET_FIELD_FOR_USER = 'USERS/SET_FIELD_FOR_USER'

export function setFieldForUser(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD_FOR_USER,
      payload: {
        field,
        value,
      },
    })
  }
}
// USER FROM END
