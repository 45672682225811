import UserAPI from '../../api/UserAPI'

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_NOT_FOUND = 'USER_LOGIN_NOT_FOUND'
export const USER_LOGIN_SERVER_ERROR = 'USER_LOGIN_SERVER_ERROR'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'

export function logInUser(login, password) {
  return dispatch => {
    dispatch({
      type: USER_LOGIN_REQUEST,
    })

    UserAPI.logIn(login, password)
      .then(res => {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: res.data,
        })
      })
      .catch(e => {
        switch (e.response.status) {
          case 404:
            dispatch({
              type: USER_LOGIN_NOT_FOUND,
            })
            break
          default:
            dispatch({
              type: USER_LOGIN_SERVER_ERROR,
            })
            break
        }
      })
  }
}

export function logOutUser() {
  return dispatch => {
    dispatch({
      type: USER_LOGOUT,
    })
  }
}
