import React, { PureComponent } from 'react'
import { Row, Col, Form, Icon, Input, Button, Alert } from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'

import { logInUser } from '../../redux/actions/UserActions'

import getUrlVars from '../../utils/getUrlVars'

class Login extends PureComponent {
  handleSubmit = e => {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.logInUser(values.username, values.password)
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const urlVars = getUrlVars()
    return (
      <div className="Login">
        <Helmet>
          <title>Вход</title>
        </Helmet>
        <Row>
          <Col xs={10} sm={10} md={10} lg={8} xl={8} offset={8}>
            <h3 className="Login__title">
              Авторизируйтесь, чтобы начать использовать приложение
            </h3>
            {this.props.user.error.code && (
              <Alert
                message={this.props.user.error.text}
                type="error"
                showIcon
                style={{ marginBottom: '1rem' }}
              />
            )}
            {urlVars['text'] && (
              <Alert
                message={decodeURI(urlVars['text'])}
                type="success"
                showIcon
                style={{ marginBottom: '1rem' }}
              />
            )}
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                {getFieldDecorator('username', {
                  rules: [
                    {
                      required: true,
                      message: 'Введите e-mail',
                    },
                  ],
                })(
                  <Input
                    prefix={
                      <Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    placeholder="E-mail"
                    size="large"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Пароль' }],
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                    }
                    type="password"
                    placeholder="Пароль"
                    size="large"
                  />
                )}
              </Form.Item>
              <Form.Item className="Login__button-row">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="Login__login-button"
                  size="large"
                  //loading={this.props.user.isLoading}
                  onClick={this.handleSubmit.bind(this)}
                >
                  Войти
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    )
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(Login)

const mapStateToProps = store => {
  return {
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logInUser: (login, password) => dispatch(logInUser(login, password)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm)
