import React, { PureComponent } from 'react'
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { history } from '../../redux/configureStore'
import { Layout, Menu, message } from 'antd'
import { connect } from 'react-redux'

import NotFound from '../Pages/NotFound/NotFoundComponent'
import Parser from '../Pages/Parser/ParserComponent'
import Cleaner from '../Pages/Cleaner/CleanerComponent'
import Converter from '../Pages/Converter/ConverterComponent'
import Users from '../Pages/Users/UsersComponent'
import MentionedPeople from '../Pages/MentionedPeople/MentionedPeopleComponent'
import OnePConverter from '../Pages/OnePConverter/OnePConverterComponent'

import { logOutUser } from '../../redux/actions/UserActions'

class MainLayout extends PureComponent {
  state = {
    collapsed: false,
  }

  onCollapse = collapsed => {
    this.setState({ collapsed })
  }

  logOut(e) {
    e.preventDefault()
    this.props.logOutUser()
  }

  componentDidMount() {
    this.checkAuth()
  }

  componentDidUpdate() {
    if (this.props.server.error.status) {
      message.error(
        this.props.server.error.status + ': ' + this.props.server.error.text
      )
    }

    this.checkAuth()
  }

  checkAuth() {
    if (!this.props.user.authorized) {
      document.location = '/login'
    }
  }

  render() {
    const { user } = this.props
    const pathname = document.location.pathname

    if (!user.authorized) {
      return null
    }

    const { Header, Content, Footer } = Layout
    const date = new Date()

    let selectKey = '1'
    switch (pathname) {
      case '/login':
        selectKey = '1'
        break
      case '/':
        selectKey = '1'
        break
      case '/clear-1p':
        selectKey = '2'
        break
      case '/tei-converter':
        selectKey = '3'
        break
      case '/users':
        selectKey = '4'
        break
      default:
        selectKey = '0'
        break
    }

    return (
      <Layout>
        <Header className="Header">
          <div className="Header__userInfo">
            {user.attributes.role !== 'demo' && user.attributes.name}
            <Link
              className="Header__exit-link"
              onClick={this.logOut.bind(this)}
              to=""
            >
              Выход
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={[selectKey]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item key="1">
              <Link to="/">Парсер</Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/1p-converter">1П-конвертер</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/clear-1p">1П-очистка</Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/tei-converter">TEI-конвертер</Link>
            </Menu.Item>
            {user.attributes.role === 'admin' && (
              <Menu.Item key="4">
                <Link to="/users">Пользователи</Link>
              </Menu.Item>
            )}
            <Menu.Item key="5">
              <Link to="/mentioned-people">Персоны</Link>
            </Menu.Item>
          </Menu>
        </Header>
        <Content className="Content">
          <div className="Content__container">
            <Router history={history}>
              <Switch>
                <Redirect from="/login" to="/" />

                <Route exact path="/" component={Parser} />
                <Route exact path="/clear-1p" component={Cleaner} />
                <Route exact path="/tei-converter" component={Converter} />
                <Route exact path="/users" component={Users} />
                <Route
                  exact
                  path="/mentioned-people"
                  component={MentionedPeople}
                />
                <Route exact path="/1p-converter" component={OnePConverter} />

                <Route component={NotFound} />
              </Switch>
            </Router>
          </div>
        </Content>
        <Footer className="Footer">
          ©{date.getFullYear()},{' '}
          <a
            href="https://prozhito.org"
            rel="noopener noreferrer"
            target="_blank"
          >
            Прожито
          </a>
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    server: store.server,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: () => dispatch(logOutUser()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
