import produce from 'immer'
import uuid from 'uuid'

import {
  CLEAR_CONSOLE,
  ADD_FILE,
  REMOVE_FILE,
  UPLOAD_FILE,
  WRITE_CONSOLE,
} from '../actions/FilesActions'
import {
  PARSER_1P_REQUEST,
  PARSER_1P_SUCCESS,
  PARSER_1P_ONE_FINISHED,
  CLEANER_1P_REQUEST,
  CLEANER_1P_SUCCESS,
  CLEANER_1P_ONE_FINISHED,
  CONVERTER_REQUEST,
  CONVERTER_SUCCESS,
  CONVERTER_ONE_FINISHED,
  CONVERTER_1P_REQUEST,
  CONVERTER_1P_SUCCESS,
  CONVERTER_1P_ONE_FINISHED,
} from '../actions/FormsActions'

const initialState = {
  files: [],

  console: {
    messages: [],
    infoCounter: 0,
    warningsCounter: 0,
  },
}

function findFileIndexByUid(files, uid) {
  let position = -1
  files.forEach((item, i) => {
    if (item.uid === uid) {
      position = i
    }
  })
  return position
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CONVERTER_1P_ONE_FINISHED:
      return produce(state, draftState => {
        let pos = findFileIndexByUid(draftState.files, action.payload.uid)
        if (pos >= 0) {
          draftState.files[pos].status = 'processed'
        }
      })
    case CONVERTER_1P_SUCCESS:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processed'
          }
        })
      })
    case CONVERTER_1P_REQUEST:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processing'
          }
        })
      })
    case CONVERTER_ONE_FINISHED:
      return produce(state, draftState => {
        let pos = findFileIndexByUid(draftState.files, action.payload.uid)
        if (pos >= 0) {
          draftState.files[pos].status = 'processed'
        }
      })
    case CONVERTER_SUCCESS:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processed'
          }
        })
      })
    case CONVERTER_REQUEST:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processing'
          }
        })
      })
    case CLEANER_1P_ONE_FINISHED:
      return produce(state, draftState => {
        let pos = findFileIndexByUid(draftState.files, action.payload.uid)
        if (pos >= 0) {
          draftState.files[pos].status = 'processed'
        }
      })
    case CLEANER_1P_SUCCESS:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processed'
          }
        })
      })
    case CLEANER_1P_REQUEST:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processing'
          }
        })
      })
    case PARSER_1P_ONE_FINISHED:
      return produce(state, draftState => {
        let pos = findFileIndexByUid(draftState.files, action.payload.uid)
        if (pos >= 0) {
          draftState.files[pos].status = 'processed'
        }
      })
    case PARSER_1P_SUCCESS:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processed'
          }
        })
      })
    case PARSER_1P_REQUEST:
      return produce(state, draftState => {
        action.payload.uids.forEach(uid => {
          let pos = findFileIndexByUid(draftState.files, uid)
          if (pos >= 0) {
            draftState.files[pos].status = 'processing'
          }
        })
      })
    case UPLOAD_FILE:
      return produce(state, draftState => {
        const position = findFileIndexByUid(
          draftState.files,
          action.payload.uid
        )
        if (position >= 0) {
          draftState.files[position].status = 'uploaded'
        }
      })
    case REMOVE_FILE:
      return produce(state, draftState => {
        const position = findFileIndexByUid(
          draftState.files,
          action.payload.uid
        )
        if (position >= 0) {
          draftState.files.splice(position, 1)
        }
      })
    case ADD_FILE:
      return produce(state, draftState => {
        if (!draftState.files.find(item => item.uid === action.payload.uid)) {
          draftState.files.unshift({
            uid: action.payload.uid,
            name: action.payload.name,
            status: 'uploading',
          })
        }
      })
    case WRITE_CONSOLE:
      return produce(state, draftState => {
        try {
          let log = decodeURIComponent(action.payload.value.replace(/\+/g, ' '))
          let logArr = []
          if (log.indexOf('[|]') > 0) {
            logArr = log.split('[|]')
            logArr[0] = logArr[0].replace('Файл ', '')
            let pos = findFileIndexByUid(draftState.files, logArr[0])
            if (pos >= 0) {
              log = `Файл ${draftState.files[pos].name}${logArr[1]}`
            }
          }
          draftState.console.messages.push({
            key: uuid(),
            type: action.payload.type,
            value: log,
          })
          switch (action.payload.type) {
            case 'info':
              draftState.console.infoCounter =
                draftState.console.infoCounter + 1
              break
            case 'warning':
              draftState.console.warningsCounter =
                draftState.console.warningsCounter + 1
              break
            default:
              break
          }
        } catch (err) {}
      })
    case CLEAR_CONSOLE:
      return produce(state, draftState => {
        draftState.console.messages = []
        draftState.console.infoCounter = 0
        draftState.console.warningsCounter = 0
      })
    default:
      return state
  }
}
